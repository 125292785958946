<template>
  <v-container class="galleries">
    <v-row v-if="!loading && folders && folders.length > 0">
      <v-col cols="12" md="6" v-for="item in folders" :key="item.value">
        <v-card link :to="{ name: 'Gallery', params: { gallery: item.value } }">
          <v-img
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
            :src="item.image"
          >
            <v-card-title>{{ item.name }}</v-card-title>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

import { db } from "@/main";
export default {
  name: "Home",
  data: () => ({
    selectedFolder: "",
    folders: []
  }),
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    error() {
      return this.$store.state.loading;
    },
    media() {
      return this.$store.state.images;
    },
    getImages() {
      return this.media.filter(ref => ref.fullPath.includes("800x800"));
    }
  },
  methods: {
    downloadUrl(ref) {
      return ref.getDownloadURL().then(url => {
        return url;
      });
    },
    resizedName(fileName, dimensions = "800x800") {
      const extIndex = fileName.lastIndexOf(".");
      const ext = fileName.substring(extIndex);
      return `${fileName.substring(0, extIndex)}_${dimensions}${ext}`;
    },
    selectFolder: function(item) {
      this.selectedFolder = item;
      this.$store.dispatch("fetchImages", item.value);
    },

    fetchFolders: function() {
      // set db refrences
      const koebbeRef = db.collection("clients").doc("koebbe");

      // get participant from usergroup
      koebbeRef.get().then(doc => {
        this.folders = doc.data().folders;
        this.selectedFolder = doc.data().folders[0];
        // this.fetchMedia(doc.data().folders[0]);
        this.$store.dispatch("fetchImages", doc.data().folders[0].value);
      });
    },
    // fetchMedia: async function(folder) {
    //   console.log(`Fetch media from folder: ${folder.value} `);
    //   const storageRef = firebase.storage().ref();
    //   const listRef = storageRef.child(`koebbe/${folder.value}`);

    //   listRef
    //     .listAll()
    //     .then(res => {
    //       const items = [];
    //       res.items.forEach(function(itemRef) {
    //         items.push(itemRef);
    //       });

    //       const filteredItems = items.filter(ref =>
    //         ref.fullPath.includes("800x800")
    //       );
    //       const filteredUrls = [];
    //       filteredItems.map(item => {
    //         if (item.fullPath.includes("800x800")) {
    //           item.getDownloadURL().then(url => {
    //             filteredUrls.push({ url, item });
    //           });
    //         }
    //       });
    //       this.media = filteredUrls;
    //       this.$store.commit("SET_IMAGES", filteredUrls);
    //       this.loading = false;
    //     })
    //     .catch(error => {
    //       this.error = error;
    //       this.loading = false;
    //     });
    // },
    displayImage(imageRef) {
      // const self = this;
      imageRef
        .getDownloadURL()
        .then(function(url) {
          this.media.push({ url });
        })
        .catch(function(error) {
          console.log({ error });
        });
    }
  },
  created() {
    // this.fetchMedia();
    this.fetchFolders();
  }
};
</script>
